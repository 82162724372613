import React from "react"
import "../styles/Trick.scss"
import { TrickMdl, DuoPoseMdl } from "cchelper"
import MarkdownContent from "./MarkdownContent"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import TwoColumnData2 from "./TwoColumnData2"
// import { has } from "lodash"
import Transitions from "./transitions"
import TagList from "./TagList"

export default function (props) {
  var { post } = props
  if (!post) return "Trick: no post!"
  // if(!(post instanceof TrickMdl))
  // 	post = new TrickMdl(post);
  console.log("POST", props)
  var transitions = post.transitions ? post.transitions : []
  if (post.hasOwnProperty("numPeople")) {
    post = new DuoPoseMdl(post)
    transitions = transitions.length > 0 ? post.transitions : []
  } else post = new TrickMdl(post)
  var img
  var imgg = getImage(post?.image?.image)
  if (imgg) {
    img = <GatsbyImage image={imgg} objectFit="cover" alt="" />
  } else if (post.videoThumb) {
    img = <img src={post.videoThumb} alt="post images" />
  }
  var video = ""
  //var thumb = '';
  var infoArr = post.getInfoArr()

  if (post.videourl !== "") {
    //thumb = `https://img.youtube.com/vi/${post.videourl}/hqdefault.jpg`
    video = (
      <iframe
        width="100%"
        height="100%"
        title="trick-video"
        src={post.videourl}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    )
  }
  var transitionsHtml = ""
  if (transitions.length > 0) {
    transitionsHtml = (
      <div className="transitions-container">
        <h3 className="transitions-title">Transiciones</h3>
        <Transitions data={transitions} pose={post.title}></Transitions>
      </div>
    )
  }

  var imgVideo = ""
  if (video === "") imgVideo = img
  else imgVideo = video

  const art = (
    <article className="trick">
      <h3 className="title">{post.title}</h3>

      <div className="type">Tipo: {post.type}</div>

      <div className="video">{imgVideo}</div>

      {/* <div className="image">
			{img}
		</div> */}

      {/* <TwoColumnData className='info' post={post}/> */}
      <TwoColumnData2 className="info" data={infoArr} />

      <MarkdownContent
        className="content"
        content={post.content}
      ></MarkdownContent>

      {transitionsHtml}

      <TagList className="tags" prefix={"/wiki-circo/tag"} tags={post.tags}>
        Tags:{" "}
      </TagList>
      <div className="date">{post.date}</div>
    </article>
  )

  return art
}
