import React from "react"
import { Link } from "gatsby"
import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';
import { kebabCase } from 'lodash'

export default function Transitions({pose, data=null, className}){
    var TransitionComponent = (poseFrom,poseTo,canGoBack)=>{
        var leftArrow = '';
        if(canGoBack){
            leftArrow = (<FaLongArrowAltLeft className='fa-icon' />)
        }
        return (
        <>
            <Link to={`/wiki-circo/${kebabCase(poseFrom)}`} 
                className='poseFrom'>{poseFrom}</Link>{leftArrow}
            <FaLongArrowAltRight className='fa-icon' />
            <Link to={`/wiki-circo/${kebabCase(poseTo)}`}
                className='poseTo'>{poseTo}</Link>
        </>
        )
    }
    
    var transitions = data.map((tran, key)=>{
        var {canGoBack, video, poseTo} = tran; 
        var from, to = undefined;
        if(poseTo.name === pose){
            from = pose;
            to = tran.poseFrom.name;
        }else{
            from = pose;
            to = tran.poseTo.name;
        }
        var transitionHtml = TransitionComponent(from,to,canGoBack);
        var videoHtml = <a href={video}>video</a>;
        return <div key={key} className='transition'>{transitionHtml} {videoHtml}</div>
    });
    return <div className='transitions'>{transitions}</div>;
}
