import React from "react"
import { Link } from "gatsby"

export default function TwoColumnData2({ data = null, className }) {
  var convert = (data, className) => {
    var res = data.map((obj, key) => {
      var { link = "", label = "-", value = "" } = obj
      var val = <div className="name-val">{value}</div>
      if (link !== "") val = <Link to={`/${link}`}>{val}</Link>
      var t = (
        <div className="dataItem" key={key}>
          <div className="name">{label}</div>
          {val}
        </div>
      )
      return t
    })
    return <div className={`data ${className}`}>{res}</div>
  }
  return convert(data, className)
}
