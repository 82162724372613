import React from "react"
import "../styles/TrickTemplate.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Trick from "../components/Trick"

export default function TrickTemplate({ pageContext, location }) {
  var { post, menuData } = pageContext
  //post = new TrickMdl(post);
  const art = (
    <Layout menuData={menuData} location={location}>
      <Seo title={post.title} />
      <Trick post={post}></Trick>
    </Layout>
  )
  return art
}
